<template>
    <div class="opModal">
        <div class="modalHeader">
            <span class="closer"><i class="fa fa-times" @click="$emit('close')"></i></span>
            <span class="title">
                <localized-text localizedKey="Richiedi pagamento" text="Richiedi pagamento"></localized-text>
            </span>
        </div>
        <form class="py-3" v-if="user">
            <!-- <accordion :open="true" class="mt-2"> -->
                <div class="midTitle text-center">
                    <localized-text localizedKey="Richiesta di pagamento" text="Richiesta di pagamento"></localized-text>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <project-country-selector v-model:project="vm.project" v-model:country="vm.country" :hideCountry="hideCountry"></project-country-selector>
                        <div class="form-group">
                            <label>
                                <localized-text 
                                    localizedKey="Importo richiesto (importo minimo: <b>{{minimo}}</b> - importo massimo: <b>{{saldo}}</b>) (*)" 
                                    text="Importo richiesto (importo minimo: <b>{{minimo}}</b> - importo massimo: <b>{{saldo}}</b>) (*)"
                                    :object="{ minimo: minimo, saldo: saldo }"
                                ></localized-text>
                            </label>
                            <input type="number" class="form-control" v-model.number="vm.importo" />
                        </div>
                        <div class="form-group">
                            <label>
                                <localized-text localizedKey="Usa ritenuta d'acconto" text="Usa ritenuta d'acconto"></localized-text>
                            </label>
                            <toggler v-model="vm.useRitenutaAcconto"></toggler>
                        </div>
                        <div v-if="vm.useRitenutaAcconto">
                            <div class="midTitle text-center">
                                <localized-text localizedKey="Per generare la ritenuta d'acconto useremo i seguenti dati. Controlla che siano corretti" 
                                    text="Per generare la ritenuta d'acconto useremo i seguenti dati. Controlla che siano corretti"></localized-text>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <fiscal-data :user="user" v-model="user.fiscalData"></fiscal-data>
                                </div>
                                <div class="col-md-6">
                                    <user-payment-data v-model="user.paymentData"></user-payment-data>
                                </div>
                            </div>
                        </div>
                        <div class="form-group" v-else>
                            <label>
                                <localized-text localizedKey="Allega la fattura (*)" text="Allega la fattura (*)"></localized-text>
                            </label>
                            <file-previewer v-model="vm.fatturaAttachment"></file-previewer>
                        </div>
                    </div>
                </div>
            <!-- </accordion> -->

            <div class="modalFooter">
                <div class="text-end">
                    <button type="button" class="btn btn-primary" :disabled="!canSend" @click="save">
                        <localized-text localizedKey="Invia richiesta" text="Invia richiesta"></localized-text>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/model';
import * as CONST from '@/const';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewmodel';
import { UserPaymentRequestServices } from '@/services/UserPaymentRequestServices';
// import { checkIfRichiestaPagamentoPossible } from '@/utils';
import { DashboardClient, ProjectConfigClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import { LocalizationServices } from '@/services/LocalizationServices';
import { checkIfRichiestaPagamentoPossible } from '@/utils';

@Options({})
export default class RichiediPagamentoModal extends Vue {

    @Prop() project: string;
    @Prop() country: string;
    @Prop() user: OM.Operator;
    
    vm: VM.RichiediPagamentoUserVm = new VM.RichiediPagamentoUserVm();
    saldo: number = 0;
    minimo: number = 0;
    hideCountry: boolean = true;

    created(){
        ProjectConfigClient.getProjectConfig(this.project)
        .then(x => {
            this.vm.country = this.country;
            if(x.countryConfigList.length > 1){
                this.hideCountry = false;
            } else {
                this.vm.country = x.countryConfigList[0].alpha2Code;
            }

            this.vm.project = this.project;
            this.vm.userIdentifier = this.user.identifier;
            this.vm.useRitenutaAcconto = false;
            this.vm.dataRicevuta = new Date().toISOString();
            this.init();
        })
    }

    init(){
        ProjectConfigClient.getPaymentRequestMinimumAmount(this.vm.project)
        .then(x => {
            this.minimo = x;
        })
        DashboardClient.getDashPortafoglio(this.user.identifier, this.vm.project, this.vm.country)
        .then( x => {
            this.saldo = x.saldoAttuale;
        });
    }

    save(){
        if(!this.canSend){
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Controlla i dati della richiesta", "Controlla i dati della richiesta"));
            return;
        }
        if(this.vm.useRitenutaAcconto && !checkIfRichiestaPagamentoPossible(this.user)){
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Controlla i dati della richiesta", "Controlla i dati della richiesta"));
            return;
        }

        UserPaymentRequestServices.richiediPagamento(this.vm)
        .then(x => {
            ModalServices.alertModal("", LocalizationServices.GetLocalizedValue("Richiesta di pagamento inviata", "Richiesta di pagamento inviata"));
            this.$emit('close');
        })
    }


    get canSend(){
        let ris: boolean = this.vm.importo > this.minimo && this.vm.importo <= this.saldo && !!this.vm.project;
        if(!this.vm.useRitenutaAcconto){
            ris = ris && !!this.vm.fatturaAttachment;
        }
        return ris;
    }

}

</script>

<style scoped>

.opModal {
    max-width: 90%;
    width: 1040px;
    /* height: 95vh; */
    overflow-y: auto;
}

</style>